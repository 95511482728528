'use strict'
const {getTravelMap, TRAVEL_TYPES} = require('../../../utils/definitionsUtils')
const name = 'BgPanRight'

const speedFactor = 0.2
const properties = {
    hideOnStart: false,
    getMediaDimensions(width, height) {
        return {width: width * (1 + speedFactor), height}
    },
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        parallaxParent: {
            type: 'element'
        }
    }
}

function register({engine, factory}) {
    /**
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        componentHeight,
        componentTop,
        siteHeight,
        viewPortHeight,
        parallaxParent,
        ...params
    } = {}) {
        if (!parallaxParent) {
            console.warn(`santa-animations: ${name}: "parallaxParent: element" is a mandatory parameter for this animation`)
        }
        const sequence = factory.sequence(params)
        const {width} = engine.getBoundingRect(parallaxParent)
        const {
            TOP_TO_BOTTOM, 
            BOTTOM_TO_TOP
        } = getTravelMap(componentHeight, componentTop, siteHeight, viewPortHeight, TRAVEL_TYPES.normal)
        
        sequence.add([
            factory.animate('BaseBgPositionX', elements, duration, delay, {
                start: TOP_TO_BOTTOM,
                end: BOTTOM_TO_TOP,
                from: -width * speedFactor / 2,
                to: width * speedFactor / 2,
                ease: 'none'
            })
        ])

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
