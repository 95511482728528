'use strict'
const {getTravelMap, TRAVEL_TYPES} = require('../../../utils/definitionsUtils')
const name = 'BgExpand'
const properties = {
    hideOnStart: false,
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        }
    }
}

function register({factory}) {
    /**
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        componentHeight,
        componentTop,
        siteHeight,
        viewPortHeight,
        ...params} = {}
    ) {
        const sequence = factory.sequence(params)
        const {
            TOP_TO_BOTTOM, 
            CENTER_TO_CENTER,
            withOffset
        } = getTravelMap(componentHeight, componentTop, siteHeight, viewPortHeight, TRAVEL_TYPES.in_last_fold)

        sequence.add([
            factory.animate('BaseBgScale', elements, duration, delay, {
                in: {
                    start: TOP_TO_BOTTOM,
                    end: withOffset(CENTER_TO_CENTER, 5),
                    scaleX: 0.8,
                    scaleY: 0.8,
                    ease: 'sine.out'
                }
            }),
            ...elements.map(element => factory.animate('BaseBgClipPath', element.children, duration, delay, {
                clipParent: element,
                in: {
                    start: TOP_TO_BOTTOM,
                    end: CENTER_TO_CENTER,
                    direction: 'center',
                    minimum: 60,
                    ease: 'sine.out'
                }
            }))
        ])
        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
