'use strict'
const {getTravelMap, TRAVEL_TYPES} = require('../../../utils/definitionsUtils')
const name = 'BgCloseUp'
const properties = {
    hideOnStart: false,
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        componentHeight: {
            type: 'number',
            min: 0
        },
        viewPortHeight: {
            type: 'number',
            min: 0
        },
        perspectiveParent: {
            type: 'element'
        }
    }
}

function register({factory}) {
    /**
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        componentHeight,
        componentTop,
        siteHeight,
        viewPortHeight,
        perspectiveParent,
        ...params
    } = {}) {
        const sequence = factory.sequence(params)
        
        {
            // We split the animation login here: the zoom is treated as "out" animation...
            const {
                TOP_TO_BOTTOM, 
                BOTTOM_TO_TOP
            } = getTravelMap(componentHeight, componentTop, siteHeight, viewPortHeight, TRAVEL_TYPES.out_first_fold)
        
            sequence.add([
                ...elements.map(element => factory.animate('BaseBgZoom', element.children, duration, delay, {
                    viewPortHeight,
                    componentHeight,
                    perspectiveParent,
                    in: {
                        start: TOP_TO_BOTTOM,
                        end: BOTTOM_TO_TOP,
                        scale: 5,
                        ease: 'none'
                    }
                }))
            ])
        }
        {
            // ...but the "fade" is not being manipulated and is treated as a "continuous" animation
            const {
                CENTER_TO_CENTER,
                BOTTOM_TO_TOP,
                withOffset,
                isInFirstFold
            } = getTravelMap(componentHeight, componentTop, siteHeight, viewPortHeight, TRAVEL_TYPES.normal)

            sequence.add(
                factory.animate('BaseBgFade', elements, duration, delay, {
                    out: {
                        start: withOffset(CENTER_TO_CENTER, isInFirstFold ? 0 : 5),
                        end: withOffset(BOTTOM_TO_TOP, isInFirstFold ? 0 : -5),
                        opacity: 0,
                        ease: 'none'
                    }
                }), 0
            )
        }
        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
