'use strict'
const {getTravelMap, TRAVEL_TYPES} = require('../../../utils/definitionsUtils')
const name = 'BgShrink'
const properties = {
    hideOnStart: false,
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        }
    }
}

function register({factory}) {
    /**
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        componentHeight,
        componentTop,
        siteHeight,
        viewPortHeight,
        ...params
    } = {}) {
        const sequence = factory.sequence(params)
        // We want to use 'out' logic for Shrink, we just want the animation to start sooner on the timeline
        // We can do it with 'isInFirstFold' and change the animation timeing.
        // We can instead pass a different travel function that conpensates half the component height like so:
        // (travel, {maxTravelHeight, extraOutDistance}) => Math.min(1, (extraOutDistance + travel - componentHeight / 2) / maxTravelHeight)
        // But I find it less readable
        const {
            TOP_TO_BOTTOM,
            TOP_TO_CENTER,
            CENTER_TO_BOTTOM, 
            CENTER_TO_CENTER,
            isInFirstFold
        } = getTravelMap(componentHeight, componentTop, siteHeight, viewPortHeight, TRAVEL_TYPES.out_first_fold)

        sequence.add(
            factory.animate('BaseBgScale', elements, duration, delay, {
                out: {
                    start: isInFirstFold ? TOP_TO_BOTTOM : CENTER_TO_BOTTOM, 
                    end: isInFirstFold ? TOP_TO_CENTER : CENTER_TO_CENTER,
                    scaleX: 0.8,
                    scaleY: 0.8,
                    ease: 'sine.out'
                }
            })
        )

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
