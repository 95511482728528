'use strict'

const name = 'BaseBgParallaxY'
const properties = {
    hideOnStart: false,
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        start: {
            type: 'number',
            min: 0,
            max: 1
        },
        end: {
            type: 'number',
            min: 0,
            max: 1
        },
        ease: {
            type: 'string'
        },
        componentHeight: {
            type: 'number',
            min: 0
        },
        viewPortHeight: {
            type: 'number',
            min: 0
        },
        speedFactor: {
            type: 'number'
        }
    }
}

function register({factory}) {
    /**
     * Base Parallax for balata media elements on scroll
     * @param {HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters (Tween values cannot be changed here, use BaseFade).
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        speedFactor,
        start,
        end,
        ease,
        componentHeight,
        viewPortHeight,
        ...params
    } = {}) {
        elements = elements.nodeName ? [elements] : Array.from(elements)

        const sequence = factory.sequence(params)

        elements.forEach(element => {
            element.style['will-change'] = 'transform'
        })

        const from = -viewPortHeight * speedFactor
        const to = componentHeight * speedFactor
        const travel = to - from

        sequence.add(
            factory.animate('BaseBgPositionY', elements, duration, delay, {
                from: from + travel * start,
                to: to - travel * (1 - end),
                start,
                end,
                ease
            }), 0
        )

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
