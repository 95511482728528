'use strict'
const {getTravelMap, TRAVEL_TYPES} = require('../../../utils/definitionsUtils')
const name = 'BgUnwind'
const properties = {
    hideOnStart: false,
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        clipParent: {
            type: 'element'
        }
    }
}

function register({factory}) {
    /**
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        clipParent,
        componentHeight,
        componentTop,
        siteHeight,
        viewPortHeight,
        ...params
    } = {}) {
        const sequence = factory.sequence(params)
        
        const {
            TOP_TO_BOTTOM, 
            TOP_TO_CENTER, 
            CENTER_TO_CENTER, 
            TOP_TO_TOP, 
            withOffset,
            isInFirstFold
        } = getTravelMap(componentHeight, componentTop, siteHeight, viewPortHeight, TRAVEL_TYPES.in_last_fold)
        
        sequence.add([
            factory.animate('BaseBgFade', elements, duration, delay, {
                baseOpacity: 0.99,
                in: {
                    start: withOffset(TOP_TO_BOTTOM, 15),
                    end: TOP_TO_CENTER,
                    opacity: 0,
                    ease: 'sine.out'
                }
            }),
            // We are animating children because we need the "overflow:hidden" of the parent balata
            ...elements.map(element => factory.animate('BaseBgRotate', element.children, duration, delay, {
                in: {
                    start: TOP_TO_BOTTOM,
                    end: Math.min(CENTER_TO_CENTER, TOP_TO_TOP),
                    rotation: 30 * (isInFirstFold ? 1 - componentTop / viewPortHeight : 1),
                    ease: 'sine.out'
                }
            })),
            ...elements.map(element => factory.animate('BaseBgClipPath', element.children, duration, delay, {
                clipParent,
                in: {
                    start: TOP_TO_BOTTOM,
                    end: Math.min(CENTER_TO_CENTER, TOP_TO_TOP),
                    direction: 'center',
                    minimum: isInFirstFold ? (1 - componentTop / viewPortHeight) * 100 : 0,
                    ease: 'none'
                }
            }))
        ])

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
