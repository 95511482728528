'use strict'
const {isNumber} = require('../../../../utils/validationUtils')

const name = 'BaseBgScale'
const properties = {
    hideOnStart: false,
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        baseScaleX: {
            type: 'number',
            default: 1
        },
        baseScaleY: {
            type: 'number',
            default: 1
        },
        in: {
            type: 'object',
            properties: {
                start: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                end: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                ease: {
                    type: 'string'
                },
                scaleX: {
                    type: 'number'
                },
                scaleY: {
                    type: 'number'
                }
            }
        },
        out: {
            type: 'object',
            properties: {
                start: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                end: {
                    type: 'number',
                    min: 0,
                    max: 1
                },
                ease: {
                    type: 'string'
                },
                scaleX: {
                    type: 'number'
                },
                scaleY: {
                    type: 'number'
                }
            }
        }
    }
}

function register({factory}) {
    /**
     * Scale balata media elements on scroll
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters (Tween values cannot be changed here, use BaseFade).
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        baseScaleX = properties.schema.baseScaleX.default,
        baseScaleY = properties.schema.baseScaleY.default,
        in: {
            start: in_start,
            end: in_end,
            ease: in_ease,
            scaleX: in_scaleX,
            scaleY: in_scaleY
        } = {},
        out: {
            start: out_start,
            end: out_end,
            ease: out_ease,
            scaleX: out_scaleX,
            scaleY: out_scaleY
        } = {},
        ...params
    } = {}) {
        const sequence = factory.sequence(params)
        const hasInAnimation = isNumber(in_start) && isNumber(in_end)
        const hasOutAnimation = isNumber(out_start) && isNumber(out_end)

        // set sequence length to full duration
        sequence.add(factory.animate('BaseNone', elements, duration, delay), 0)

        // Place in animation on timeline
        if (hasInAnimation) {
            const from = {}
            const to = {}
            if (in_end < in_start) {
                console.warn(`santa-animations: ${name}: "in" end value ${in_end} must be larger than "in" start value ${in_start}`)
            }

            if (typeof in_scaleX !== 'undefined') {
                from.scaleX = in_scaleX
                to.scaleX = baseScaleX
            }

            if (typeof in_scaleY !== 'undefined') {
                from.scaleY = in_scaleY
                to.scaleY = baseScaleY
            }

            const _duration = duration * Math.max(in_end - in_start, 0)
            const _delay = delay + duration * in_start
            sequence.add(factory.animate('BaseScale', elements, _duration, _delay, {
                from,
                to,
                ease: in_ease,
                force3D: true,
                immediateRender: !hasOutAnimation || in_start < out_start
            }), 0)
        }

        // place out animation on timeline
        if (hasOutAnimation) {
            const from = {}
            const to = {}
            if (out_end < out_start) {
                console.warn(`santa-animations: ${name}: "out" end value ${out_end} must be larger than "out" start value ${out_start}`)
            }

            if (typeof out_scaleX !== 'undefined') {
                from.scaleX = baseScaleX
                to.scaleX = out_scaleX
            }

            if (typeof out_scaleY !== 'undefined') {
                from.scaleY = baseScaleY
                to.scaleY = out_scaleY
            }

            const _duration = duration * Math.max(out_end - out_start, 0)
            const _delay = delay + duration * out_start
            sequence.add(factory.animate('BaseScale', elements, _duration, _delay, {
                from,
                to,
                ease: out_ease,
                force3D: true,
                immediateRender: !hasInAnimation || out_start < in_start
            }), 0)
        }

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
